import { css } from 'styled-components';

import { BorderRadius, Color, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { OpacityType, addOpacity } from '@/global/utils/colorUtil';

export const LinkBaseStyles = css`
  text-decoration: underline;
  padding: 2px ${spacing.x05};
  border: 2px solid ${Color.Transparent};
  border-radius: ${BorderRadius.Small};
  color: ${Color.Blue};
  font-weight: ${FontWeight.Regular};

  // We don't want to affect the "visual spacing" below the button as seen without hover/active.
  // So adjust the position the same amount as the padding (which is only visible on hover/active)
  margin: calc(-${spacing.x1} - 2px) calc(-${spacing.x05} - 2px); // spacing and 2px border

  outline: none;

  &:focus-visible {
    border: 2px solid ${Color.Ocean};
  }

  &:hover {
    background-color: ${addOpacity(Color.Blue, OpacityType.Hover)};
  }
`;
