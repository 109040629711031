'use client';

import { ReactNode } from 'react';

import { HallonColor } from '@/global/utils/styleTypes';

import { Content } from './HtmlFromCMS.styled';

type HtmlFromCMSProps = {
  html?: ReactNode;
  linkColor?: HallonColor;
  richText?: boolean;
  'data-testid'?: string;
};
const HtmlFromCMS = (props: HtmlFromCMSProps) => {
  const { html, richText = false, linkColor, 'data-testid': dataTestId } = props;
  if (!html) return null;

  return (
    <Content
      data-testid={dataTestId}
      $richText={richText}
      $linkColor={linkColor}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default HtmlFromCMS;
