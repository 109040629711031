import colorStyles from './Color.module.css';

export enum Color {
  Blue = '#004496',
  BlueActive = '#044088',
  BlueHover = '#02418C',
  Brown = '#673600',
  DarkGray1 = '#767676',
  DarkGray1Active = '#EFEFEF',
  DarkGray1Hover = '#F5F5F5',
  DarkGray2 = '#C4C4C4',
  Forest = '#005112',
  Gray1 = '#DCDCDE',
  Gray2 = '#EEEEF0',
  Gray3 = '#F9F9FA',
  Hallon1 = '#630132',
  Hallon1Active = '#F1E3E8',
  Hallon1Hover = '#F6ECF0',
  Hallon2 = '#86004B',
  Hallon3 = '#FF6DF0',
  Hallon4 = '#FF98F5',
  Hallon4Active = '#E489DB',
  Hallon4Hover = '#ED8EE4',
  Hallon5 = '#FFC2F9',
  Hallon6 = '#FFECFD',
  Hallon6Hover = '#EDDCEB',
  Hallon6Active = '#E4D3E2',
  Lemon1 = '#FEFC00',
  Lemon2 = '#FFFB8B',
  Lemon3 = '#FFFDC3',
  MineShaft = '#1F1F1F',
  MineShaftActive = '#E4E4E4',
  MineShaftHover = '#EDEDED',
  Nyanza = '#E4F5E4',
  NyanzaHover = '#D4E4D4',
  NyanzaActive = '#CCDBCC',
  Ocean = '#00B2FF',
  Rose = '#FFEAE7',
  Sand = '#F6F1DC',
  Sky = '#E4F2FF',
  White = '#FFFFFF',
  WhiteActive = '#FFFFFF1F',
  WhiteHover = '#FFFFFF14',

  Transparent = 'transparent',
}

const shadowColor = 'rgba(31,31,31,0.10)'; // 10% of mineshaft
export const shadows = {
  default: `0 2px 5px 0 ${shadowColor}`,
  hover: `0 3px 6px 0 ${shadowColor}`,
  menu: `0 12px 25px 0 ${shadowColor}`,
  tabbar: `0 -2px 5px 0 ${shadowColor}`,
  noTopShadow: `0 2px 5px -6px ${shadowColor}`,
};

export const bankIdColor = '#193E4F';

export enum BorderRadius {
  XXLarge = '48px',
  XLarge = '28px',
  Large = '20px',
  Medium = '10px',
  Small = '5px',
  XSmall = '3px',
}

export enum NewBorderRadius {
  Large = '48px',
  Medium = '24px',
  Small = '12px',
  XSmall = '4px',
}

const BaseSpacing = 8;

/**
 * @description Used for padding, margin, top, left, right, bottom.
 */
export const spacing = {
  /** 4px */
  x05: `${0.5 * BaseSpacing}px`,
  /** 8px previously xs */
  x1: `${1 * BaseSpacing}px`,
  /** 12px */
  x15: `${1.5 * BaseSpacing}px`,
  /** 16px previously sm */
  x2: `${2 * BaseSpacing}px`,
  /** 20px */
  x25: `${2.5 * BaseSpacing}px`,
  /** 24px previously md */
  x3: `${3 * BaseSpacing}px`,
  /** 32px previously lg */
  x4: `${4 * BaseSpacing}px`,
  /** 40px previously xl */
  x5: `${5 * BaseSpacing}px`,
  /** 48px previously xxl */
  x6: `${6 * BaseSpacing}px`,
  /** 56px */
  x7: `${7 * BaseSpacing}px`,
  /** 64px */
  x8: `${8 * BaseSpacing}px`,
  /** 72px */
  x9: `${9 * BaseSpacing}px`,
  /** 80px */
  x10: `${10 * BaseSpacing}px`,
} as const;

export function getColorClass(color: Color): string {
  switch (color) {
    case Color.Blue:
      return colorStyles.colorBlue;
    case Color.BlueActive:
      return colorStyles.colorBlueActive;
    case Color.BlueHover:
      return colorStyles.colorBlueHover;
    case Color.Brown:
      return colorStyles.colorBrown;
    case Color.DarkGray1:
      return colorStyles.colorDarkGray1;
    case Color.DarkGray1Active:
      return colorStyles.colorDarkGray1Active;
    case Color.DarkGray1Hover:
      return colorStyles.colorDarkGray1Hover;
    case Color.DarkGray2:
      return colorStyles.colorDarkGray2;
    case Color.Forest:
      return colorStyles.colorForest;
    case Color.Gray1:
      return colorStyles.colorGray1;
    case Color.Gray2:
      return colorStyles.colorGray2;
    case Color.Gray3:
      return colorStyles.colorGray3;
    case Color.Hallon1:
      return colorStyles.colorHallon1;
    case Color.Hallon1Active:
      return colorStyles.colorHallon1Active;
    case Color.Hallon1Hover:
      return colorStyles.colorHallon1Hover;
    case Color.Hallon2:
      return colorStyles.colorHallon2;
    case Color.Hallon3:
      return colorStyles.colorHallon3;
    case Color.Hallon4:
      return colorStyles.colorHallon4;
    case Color.Hallon4Active:
      return colorStyles.colorHallon4Active;
    case Color.Hallon4Hover:
      return colorStyles.colorHallon4Hover;
    case Color.Hallon5:
      return colorStyles.colorHallon5;
    case Color.Hallon6:
      return colorStyles.colorHallon6;
    case Color.Hallon6Hover:
      return colorStyles.colorHallon6Hover;
    case Color.Hallon6Active:
      return colorStyles.colorHallon6Active;
    case Color.Lemon1:
      return colorStyles.colorLemon1;
    case Color.Lemon2:
      return colorStyles.colorLemon2;
    case Color.Lemon3:
      return colorStyles.colorLemon3;
    case Color.MineShaft:
      return colorStyles.colorMineShaft;
    case Color.MineShaftActive:
      return colorStyles.colorMineShaftActive;
    case Color.MineShaftHover:
      return colorStyles.colorMineShaftHover;
    case Color.Nyanza:
      return colorStyles.colorNyanza;
    case Color.NyanzaHover:
      return colorStyles.colorNyanzaHover;
    case Color.NyanzaActive:
      return colorStyles.colorNyanzaActive;
    case Color.Ocean:
      return colorStyles.colorOcean;
    case Color.Rose:
      return colorStyles.colorRose;
    case Color.Sand:
      return colorStyles.colorSand;
    case Color.Sky:
      return colorStyles.colorSky;
    case Color.White:
      return colorStyles.colorWhite;
    case Color.WhiteActive:
      return colorStyles.colorWhiteActive;
    case Color.WhiteHover:
      return colorStyles.colorWhiteHover;
    case Color.Transparent:
      return colorStyles.colorTransparent;
    default:
      return '';
  }
}
