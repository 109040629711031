import { HallonColor } from '@/global/utils/styleTypes';

export enum OpacityType {
  Hover = 'hover',
  Active = 'active',
}

export const addOpacity = (color: HallonColor, type: OpacityType) => {
  switch (type) {
    case OpacityType.Hover:
      return `${color}14`;
    case OpacityType.Active:
      return `${color}1f`;
    default:
      return color;
  }
};

export const hexLighterThanThreshold = (hexCode: string, threshold: number) => {
  let parsedHexCode = hexCode;
  if (hexCode.includes('#')) {
    parsedHexCode = hexCode.substring(1, 7);
  }

  const r = parseInt(parsedHexCode.substring(0, 2), 16);
  const g = parseInt(parsedHexCode.substring(2, 4), 16);
  const b = parseInt(parsedHexCode.substring(4, 6), 16);

  return r > threshold && g > threshold && b > threshold;
};
