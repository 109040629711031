'use client';

import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { Color, NewBorderRadius, shadows, spacing } from '@/global/style/variables';

import { ContentAlignment } from './Card.types';

export const Content = styled.div`
  flex: 1 1 100%;
  max-width: 100%;
  scroll-margin: 150px;
  border-radius: ${NewBorderRadius.Medium};
  position: relative;
`;

export const Container = styled(motion.div)<{ $fullHeight: boolean }>`
  position: relative;
  box-shadow: ${shadows.default};
  border-radius: ${NewBorderRadius.Medium};
  width: 100%;
  ${({ $fullHeight }) => $fullHeight && `height: 100%`}
`;

const noHorizontalPadding = css`
  padding-left: 0px;
  padding-right: 0px;
`;

const noTopPadding = css`
  padding-top: 0px;
`;

const noBottomPadding = css`
  padding-bottom: 0px;
`;

export const InnerContainer = styled.div<{
  $topPadding: boolean;
  $bottomPadding: boolean;
  $horizontalPadding: boolean;
  $contentAlignment?: ContentAlignment;
  $bgColor: Color;
  $centerVertical: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${spacing.x25} ${spacing.x2} ${spacing.x4} ${spacing.x2};
  align-items: ${({ $contentAlignment }) => $contentAlignment || 'center'};
  border-radius: ${NewBorderRadius.Medium};
  background-color: ${({ $bgColor }) => $bgColor};
  justify-content: ${({ $centerVertical }) => ($centerVertical ? 'center' : 'start')};

  ${above.sm`
    flex-direction: column;
    padding: ${spacing.x3} ${spacing.x3} ${spacing.x5} ${spacing.x3};

    ${({ $horizontalPadding }) => !$horizontalPadding && noHorizontalPadding}
    ${({ $topPadding }) => !$topPadding && noTopPadding}
    ${({ $bottomPadding }) => !$bottomPadding && noBottomPadding}
  `}

  ${({ $horizontalPadding }) => !$horizontalPadding && noHorizontalPadding}
  ${({ $topPadding }) => !$topPadding && noTopPadding}
  ${({ $bottomPadding }) => !$bottomPadding && noBottomPadding}

  ${({ $topPadding, $bottomPadding }) => !$topPadding && !$bottomPadding && `overflow: hidden;`}
`;
