'use client';

import { CSSObject, Interpolation, css } from 'styled-components';

export enum GridMargins {
  xxs = '16px',
  xs = '16px',
  sm = '24px',
  md = '24px',
  lg = '40px',
}

export enum ContentWidth {
  textMax = '564px',
  sm = '648px',
  lg = '1320px',
}

export enum BreakPoint {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xlg = 'xlg',
}

export const breakpointsValues = {
  [BreakPoint.xxs]: 375,
  [BreakPoint.xs]: 480,
  [BreakPoint.sm]: 768,
  [BreakPoint.md]: 992,
  [BreakPoint.lg]: 1148,
  [BreakPoint.xlg]: 1400,
} as const;

export const breakpoints = {
  [BreakPoint.xxs]: `${breakpointsValues.xxs}px`,
  [BreakPoint.xs]: `${breakpointsValues.xs}px`,
  [BreakPoint.sm]: `${breakpointsValues.sm}px`,
  [BreakPoint.md]: `${breakpointsValues.md}px`,
  [BreakPoint.lg]: `${breakpointsValues.lg}px`,
  [BreakPoint.xlg]: `${breakpointsValues.xlg}px`,
} as const;

// Results in a css string literal with a media-query and the passed in style,
const breakPointSpecificStyle = (
  breakPoint: BreakPoint,
  mediaProperty: 'min' | 'max',
  first: CSSObject | TemplateStringsArray,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  ...rest: Interpolation<any>[]
) => {
  return css`
    @media (${mediaProperty}-width: ${breakpoints[breakPoint]}) {
      ${css(first, ...rest)}
    }
  `;
};

export const above = {
  [BreakPoint.xxs]: breakPointSpecificStyle.bind(this, BreakPoint.xxs, 'min'),
  [BreakPoint.xs]: breakPointSpecificStyle.bind(this, BreakPoint.xs, 'min'),
  [BreakPoint.sm]: breakPointSpecificStyle.bind(this, BreakPoint.sm, 'min'),
  [BreakPoint.md]: breakPointSpecificStyle.bind(this, BreakPoint.md, 'min'),
  [BreakPoint.lg]: breakPointSpecificStyle.bind(this, BreakPoint.lg, 'min'),
  [BreakPoint.xlg]: breakPointSpecificStyle.bind(this, BreakPoint.xlg, 'min'),
};

export const below = {
  [BreakPoint.xxs]: breakPointSpecificStyle.bind(null, BreakPoint.xxs, 'max'),
  [BreakPoint.xs]: breakPointSpecificStyle.bind(null, BreakPoint.xs, 'max'),
  [BreakPoint.sm]: breakPointSpecificStyle.bind(null, BreakPoint.sm, 'max'),
  [BreakPoint.md]: breakPointSpecificStyle.bind(null, BreakPoint.md, 'max'),
  [BreakPoint.lg]: breakPointSpecificStyle.bind(null, BreakPoint.lg, 'max'),
  [BreakPoint.xlg]: breakPointSpecificStyle.bind(null, BreakPoint.xlg, 'max'),
};
