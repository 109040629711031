'use client';

import { ForwardedRef, forwardRef } from 'react';
import { Color } from '@/global/style/variables';

import { Container, Content, InnerContainer } from './Card.styled';
import { ContentAlignment } from './Card.types';

export type CardProps = {
  ['data-testid']: string;
  ['data-cy']?: string;
  children: React.ReactNode;
  topPadding?: boolean;
  bottomPadding?: boolean;
  horizontalPadding?: boolean;
  contentAlignment?: ContentAlignment;
  fullHeight?: boolean;
  className?: string;
  id?: string;
  bgColor?: Color;
  centerVertical?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Card = forwardRef((props: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    children,
    topPadding = true,
    bottomPadding = true,
    horizontalPadding = true,
    contentAlignment,
    fullHeight = false,
    className,
    id = '',
    bgColor = Color.White,
    centerVertical = false,
    onClick,
    'data-cy': dataCy,
    'data-testid': dataTestId,
  } = props;

  return (
    <Content ref={ref} onClick={onClick} className={className} data-testid={dataTestId} data-cy={dataCy} id={id}>
      <Container $fullHeight={fullHeight}>
        <InnerContainer
          $contentAlignment={contentAlignment}
          $topPadding={topPadding}
          $bottomPadding={bottomPadding}
          $horizontalPadding={horizontalPadding}
          $bgColor={bgColor}
          $centerVertical={centerVertical}
        >
          {children}
        </InnerContainer>
      </Container>
    </Content>
  );
});

Card.displayName = 'Card';

export default Card;
