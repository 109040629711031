import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { HallonColor } from '@/global/utils/styleTypes';

import { LinkBaseStyles } from '@/components/LinkLookingButton/LinkLookingButton.styled';

const richTextCss = css`
  > :first-child {
    margin-top: 0;
  }

  p,
  span {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  p {
    margin-top: ${spacing.x3};
    margin-bottom: ${spacing.x3};
    color: inherit;
  }

  em {
    font-style: italic;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  b,
  strong {
    font-weight: ${FontWeight.SemiBold};
  }

  h2,
  h3,
  h4,
  h5 {
    margin-top: ${spacing.x5};
    margin-bottom: ${spacing.x3};
  }

  h1 {
    font-size: 3.2rem;
    line-height: 4rem;
    margin-bottom: ${spacing.x5};
    margin-top: ${spacing.x9};

    ${above.sm`
      font-size: 5.6rem;
      line-height: 6.4rem;
      margin-top: ${spacing.x6};
    `}
  }

  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;

    ${above.sm`
      font-size: 3.2rem;
      line-height: 4rem;
    `}
  }

  h3 {
    font-size: 2rem;
    line-height: 2.8rem;

    ${above.sm`
      font-size: 2.4rem;
      line-height: 3.2rem;
    `}
  }

  h4,
  h5 {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  ul,
  ol {
    margin-bottom: ${spacing.x3};

    li {
      margin-bottom: ${spacing.x2};
      line-height: 2.4rem;
    }
  }

  ul {
    margin-left: ${spacing.x5};

    li {
      list-style: none;
      position: relative;

      &::before {
        content: '';

        width: 8px;
        height: 8px;
        background-color: currentColor;
        border-radius: 50%;
        position: absolute;
        left: -30px;
        top: 8px;
      }
    }

    ${above.sm`
      margin-left: ${spacing.x4};
    `}
  }

  ol {
    margin-left: ${spacing.x5};
    counter-reset: list-counter;
    li {
      counter-increment: list-counter;
      list-style: none;
      position: relative;

      &::before {
        content: counter(list-counter) '.';
        position: absolute;
        left: -30px;
      }
    }

    ${above.sm`
      margin-left: ${spacing.x4};
    `}
  }
`;

export const Content = styled.div<{ $richText: boolean; $linkColor?: HallonColor }>`
  p + p,
  li + li {
    margin-top: ${spacing.x1};
  }

  ul,
  ol {
    margin: ${spacing.x1} 0 ${spacing.x2} ${spacing.x3};
  }

  a {
    ${LinkBaseStyles}
    ${({ $linkColor }) => css`
      color: ${$linkColor};
    `}
    &:has(img) {
      &:hover {
        background-color: transparent;
      }
    }
  }

  ${({ $richText }) => $richText && richTextCss}
`;
